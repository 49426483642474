const actions = {
  SET_STATE: 'page/SET_STATE',
  ADD: 'page/ADD',
  GET: 'page/GET',
  DELETE: 'page/DELETE',
  UPDATE: 'page/UPDATE',
  WELCOME_UPDATE: 'welcome/UPDATE',
}

export default actions
