/* eslint-disable no-unused-vars */
// import { IncomingWebhook } from '@slack/webhook'
import axios from 'axios'

const sendMessage = async (title, header, sections) => {
  // const webhook = new IncomingWebhook(process.env.REACT_APP_SLACK_WEBHOOK_URL)

  // await webhook.send({
  //   channel: process.env.REACT_APP_SLACK_WEBHOOK_CHANNEL,
  //   text: message,
  // })
  const headers = {
    'Content-Type': 'application/json',
  }
  const data = {
    channel: process.env.REACT_APP_SLACK_WEBHOOK_CHANNEL,
    text: title,
    blocks: [
      {
        type: 'header',
        text: { type: 'plain_text', text: `${header}` },
      },
      {
        type: 'section',
        text: {
          type: 'plain_text',
          text: title,
          emoji: true,
        },
      },
    ].concat(sections || []),
  }
  console.log('data:', data)
  console.log(JSON.stringify(data))
  // console.log(axios.defaults.headers)
  await axios.post(process.env.REACT_APP_SLACK_WEBHOOK_URL, JSON.stringify(data), {
    // headers,
    withCredentials: false,
    transformRequest: [
      (aData, aHeaders) => {
        delete aHeaders.post['Content-Type']
        return aData
      },
    ],
  })
}
// eslint-disable-next-line import/prefer-default-export
export { sendMessage }
