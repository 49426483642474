// import store from 'store'
import actions from './actions'

const initialState = {}

export default function slackReducer(state = initialState, action) {
  switch (action.type) {
    case actions.START_LIVESTREAMING:
      return state
    case actions.FINISH_LIVESTREAMING:
      return state
    default:
      return state
  }
}
