import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'App'
import * as firebase from 'services/firebase'
import { USER } from 'constant'
import PATH from 'path.config'
import actions from './actions'

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(firebase.login, email, password)
  // console.log(success);

  if (success) {
    try {
      yield put({
        type: 'user/LOAD_CURRENT_ACCOUNT',
      })
      yield history.push(PATH.ADMIN.HOME)
      notification.success({
        message: '로그인 성공',
        description: '로그인 되었습니다.',
        duration: 1,
      })
    } catch (error) {
      console.log('LOGIN saga', error)
    }
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* REGISTER({ payload }) {
  const { email, password, ...data } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(
    firebase.register,
    email,
    password,
    data,
    // name,
    // nickname,
    // phone,
    // department,
    // work,
    // attendance,
    // type,
    // role,
  )

  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield history.push(PATH.AUTH.REGISTER_COMPLETE)
    notification.success({
      message: '접수 완료.',
      description: '컨퍼런스 등록 신청이 접수되었습니다.',
      duration: 2,
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* USER_EDIT({ payload }) {
  // console.log('USER_EDIT', payload)
  // const { email, name, nickname, phone, department, work, attendance, type, role } = payload
  console.log(payload)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  // console.log(firebase.updateUser)

  const success = yield call(firebase.updateUser, payload)

  if (success) {
    notification.success({
      message: '수정 완료.',
      description: '회원 정보가 수정되었습니다.',
      duration: 1,
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* USER_DELETE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  // console.log(firebase.updateUser)
  const success = yield call(firebase.deleteUser, payload)
  // console.log(success)
  if (success) {
    notification.success({
      message: '삭제 완료.',
      description: '참가자가 삭제되었습니다.',
      duration: 1,
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  try {
    const response = yield call(firebase.currentAccount)
    if (response) {
      // const { id, name, nickname, phone, department, work, attendance, type, role, email } = response
      const { id, role, email } = response
      // console.log(role)
      if (role === USER.TYPE.ADMIN) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            id,
            // name,
            // nickname,
            // phone,
            // department,
            // work,
            // attendance,
            // type,
            role,
            email,
            authorized: true,
          },
        })
      } else {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            authorized: false,
          },
        })
        throw new Error('User is not admin role')
      }
    }
  } catch (error) {
    notification.error({
      message: '로그인 실패',
      description: '관리자 계정만 로그인이 가능합니다.',
      duration: 3,
    })
    // console.log('LOAD_CURRENT_ACCOUNT', error)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield call(firebase.logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      // name: '',
      // nickname: '',
      // phone: '',
      // department: '',
      // work: '',
      // attendance: '',
      // type: '',
      role: '',
      email: '',
      authorized: false,
      loading: false,
    },
  })
}

export function* FORGOT_PASSWORD({ payload }) {
  const result = yield call(firebase.sendFindPasswordMail, payload.email)
  // console.log(result)
  if (result) {
    yield history.push(PATH.AUTH_LOGIN)
    notification.success({
      message: '메일 보내기 성공',
      description:
        '비밀번호 재설정 메일이 전송되었습니다. 메일을 확인하신 후 비밀번호를 재설정 해주세요. ',
      duration: 3,
    })
  }
}

// eslint-disable-next-line require-yield
export function* MOVE_TO_SCHEDULE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      userListLoading: true,
    },
  })
  yield call(firebase.moveUserToSchedule, payload)

  yield put({
    type: 'user/SET_STATE',
    payload: {
      userListLoading: false,
    },
  })
  // results
  // console.log("🚀 ~ file: sagas.js ~ line 280 ~ function*MOVE_TO_SCHEDULE ~ result", result)
}

export function* DELETE_MULTIPLE_USERS({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      userListLoading: true,
    },
  })
  yield call(firebase.deleteMultipleUsers, payload)

  yield put({
    type: 'user/SET_STATE',
    payload: {
      userListLoading: false,
    },
  })
  // results
  // console.log("🚀 ~ file: sagas.js ~ line 280 ~ function*MOVE_TO_SCHEDULE ~ result", result)
}

export function* SEND_SMS({ payload }) {
  yield call(firebase.sendSMS, payload)
  notification.success({
    message: 'SMS 메시지 전송 완료',
    description: 'SMS 메시지가 전송되었습니다 ',
    duration: 3,
  })
  // results
  // console.log("🚀 ~ file: sagas.js ~ line 280 ~ function*MOVE_TO_SCHEDULE ~ result", result)
}

export function* REMOVE_ALL_SMS_TOKEN() {
  const collectionPath = 'smsToken'

  yield call(firebase.deleteCollection, collectionPath, 200)
  notification.success({
    message: '로그인 토큰 삭제 완료',
    description: '모든 로그인 토큰이 삭제되어 SMS 링크로 로그인 할 수 없습니다. ',
    duration: 3,
  })
}

export function* MULTIPLE_USERS_EDIT({ payload }) {
  // console.log(payload)
  const success = yield call(firebase.updateMultipleUsers, payload)
  console.log(success)
  if (success) {
    notification.success({
      message: '수정 완료.',
      description: '회원 정보가 수정되었습니다.',
      duration: 1,
    })
  }
}

export function* SEND_KAKAO_LOGIN_LINK({ payload }) {
  console.log(payload)
  const success = yield call(firebase.sendKakaoLoginLink, payload)
  console.log(success)
  if (success) {
    notification.success({
      message: '전송 완료.',
      description: '카카오톡 로그인 링크가 전송되었습니다.',
      duration: 2,
    })
  }
}

export function* CLEAN_UP() {
  const success = yield call(firebase.cleanUpUsers)
  console.log(success)
  if (success) {
    notification.success({
      message: '정리 완료.',
      description: '회원 데이터베이스 정리가 완료되었습니다.',
      duration: 2,
    })
  }
}

export function* RESET_STATUS() {
  const success = yield call(firebase.resetUserOnlineStatus)
  console.log(success)
  if (success) {
    notification.success({
      message: '삭제 완료.',
      description: '회원 온라인 정보가 초기화 되었습니다.',
      duration: 2,
    })
  } else {
    notification.success({
      message: '삭제 오류.',
      description: '회원 온라인 정보가 초기화 되지 않았습니다.',
      duration: 2,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.USER_DELETE, USER_DELETE),
    takeEvery(actions.USER_EDIT, USER_EDIT),
    takeEvery(actions.MULTIPLE_USERS_EDIT, MULTIPLE_USERS_EDIT),
    takeEvery(actions.FORGOT_PASSWORD, FORGOT_PASSWORD),
    takeEvery(actions.MOVE_TO_SCHEDULE, MOVE_TO_SCHEDULE),
    takeEvery(actions.DELETE_MULTIPLE_USERS, DELETE_MULTIPLE_USERS),
    takeEvery(actions.SEND_SMS, SEND_SMS),
    takeEvery(actions.REMOVE_ALL_SMS_TOKEN, REMOVE_ALL_SMS_TOKEN),
    takeEvery(actions.SEND_KAKAO_LOGIN_LINK, SEND_KAKAO_LOGIN_LINK),
    takeEvery(actions.CLEAN_UP, CLEAN_UP),
    takeEvery(actions.RESET_STATUS, RESET_STATUS),

    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
