import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import sessions from './session/reducers'
import vod from './vod/reducers'
import channel from './channel/reducers'
import survey from './survey/reducers'
import userProfileField from './userProfileField/reducers'
import schedule from './schedule/reducers'
import chat from './chat/reducers'
import logger from './Logger/reducers'
import Project from './Project/reducers'
import Exam from './exam/reducers'
import Ticket from './Ticket/reducers'
import Sponsor from './sponsor/reducers'
import Speaker from './speaker/reducers'
import PageSegments from './pageSegments/reducers'
import Page from './page/reducers'
import userType from './userType/reducers'
import publicMenu from './PublicMenu/reducers'
import slack from './slack/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    sessions,
    vod,
    channel,
    survey,
    userProfileField,
    schedule,
    chat,
    logger,
    Project,
    Exam,
    Ticket,
    Sponsor,
    Speaker,
    PageSegments,
    Page,
    userType,
    publicMenu,
    slack,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
  })
