const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  REGISTER: 'user/REGISTER',
  USER_EDIT: 'user/USER_EDIT',
  MULTIPLE_USERS_EDIT: 'user/MULTIPLE_USERS_EDIT',
  USER_DELETE: 'user/USER_DELETE',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  FORGOT_PASSWORD: 'user/FORGOT_PASSWORD',
  BEACON: 'user/BEACON',
  MOVE_TO_SCHEDULE: 'user/MOVE_TO_SCHEDULE',
  DELETE_MULTIPLE_USERS: 'user/DELETE_MULTIPLE_USERS',
  SEND_SMS: 'user/SEND_SMS',
  REMOVE_ALL_SMS_TOKEN: 'user/REMOVE_ALL_SMS_TOKEN',
  SEND_KAKAO_LOGIN_LINK: 'user/SEND_KAKAO_LOGIN_LINK',
  CLEAN_UP: 'user/CLEAN_UP',
  RESET_STATUS: 'user/RESET_STATUS',
}

export default actions
