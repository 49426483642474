import { all, takeEvery, call } from 'redux-saga/effects'
import actions from './actions'
import { sendMessage } from '../../services/slack'

export function* START_LIVESTREAMING({ payload }) {
  console.log('payload:', payload)
  const { title, header, sections } = payload
  yield call(sendMessage, title, header, sections)
}
export function* FINISH_LIVESTREAMING({ payload }) {
  console.log('payload:', payload)
  const { title, header, sections } = payload
  yield call(sendMessage, title, header, sections)
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.START_LIVESTREAMING, START_LIVESTREAMING),
    takeEvery(actions.FINISH_LIVESTREAMING, FINISH_LIVESTREAMING),
  ])
}
