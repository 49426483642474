// eslint-disable-next-line
import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import session from './session/sagas'
import vod from './vod/sagas'
import channel from './channel/sagas'
import survey from './survey/sagas'
import userProfileField from './userProfileField/sagas'
import userType from './userType/sagas'
import schedule from './schedule/sagas'
import chat from './chat/sagas'
import logger from './Logger/sagas'
import Project from './Project/sagas'
import Exam from './exam/sagas'
import Ticket from './Ticket/sagas'
import Sponsor from './sponsor/sagas'
import Speaker from './speaker/sagas'
import Page from './page/sagas'
import PageSegments from './pageSegments/sagas'
import PublicMenu from './PublicMenu/sagas'
import Slack from './slack/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    session(),
    vod(),
    channel(),
    survey(),
    userProfileField(),
    userType(),
    schedule(),
    chat(),
    logger(),
    Project(),
    Exam(),
    Ticket(),
    Sponsor(),
    Speaker(),
    Page(),
    PageSegments(),
    PublicMenu(),
    Slack(),
  ])
}
