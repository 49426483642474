/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
// import Loader from 'components/cleanui/layout/Loader'
import { TEXT } from 'constant'
import PATH from 'path.config'
import AuthLayout from './Auth'
import AdminLayout from './Admin'

const Layouts = {
  auth: AuthLayout,
  admin: AdminLayout,
}

const mapStateToProps = ({ user }) => ({ user })
let previousPath = ''

const Layout = ({ user, children, location: { pathname, search } }) => {
  // NProgress & ScrollTop Management
  const currentPath = pathname + search
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0)
    NProgress.start()
  }
  setTimeout(() => {
    NProgress.done()
    previousPath = currentPath
  }, 300)

  // Layout Rendering
  const getLayout = () => {
    if (
      /^\/auth(?=\/|$)/i.test(pathname) ||
      pathname === PATH.AUTH.REGISTER_COMPLETE ||
      pathname === PATH.AUTH.REGISTER_SHORT
    ) {
      return 'auth'
    }

    if (/^\/admin(?=\/|$)/i.test(pathname)) {
      return 'admin'
    }
    return 'admin'
  }

  const needTopbar = () => {
    if (
      /^\/auth(?=\/|$)/i.test(pathname) ||
      pathname === PATH.AUTH.REGISTER_COMPLETE ||
      pathname === PATH.AUTH.REGISTER_SHORT
    ) {
      return false
    }

    // console.log(pathname)
    if (/^\/admin(?=\/|$)/i.test(pathname)) {
      if (
        pathname === PATH.ADMIN.CONTROLBOX ||
        // pathname === PATH.ADMIN.HOME ||
        pathname === PATH.ADMIN.DIRECTOR ||
        pathname === PATH.ADMIN.CHAT ||
        pathname === PATH.ADMIN.LIVE ||
        pathname === PATH.ADMIN.LIVE_SURVEY ||
        // pathname === PATH.ADMIN.LOG_REPORT ||
        pathname === PATH.ADMIN.VOD
      ) {
        return true
      }
      return false
    }
    return true
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = user.authorized
  const isUserLoading = user.loading
  const isUserAdmin = user.role === 'admin'
  const isAuthLayout = getLayout() === 'auth'
  const isAdminLayout = getLayout() === 'admin'

  const BootstrappedLayout = () => {
    let topbar = needTopbar()

    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
      return null
    }

    // redirect to login page if current is not login page and user not authorized
    if (!isAuthLayout && !isUserAuthorized) {
      return <Redirect to={PATH.AUTH.LOGIN} />
    }
    if (!isUserAdmin && isAdminLayout) {
      return <Redirect to={PATH.AUTH.LOGIN} />
    }

    // in other case render previously set layout
    return <Container topbar={topbar}>{children}</Container>
  }

  return (
    <Fragment>
      <Helmet titleTemplate={`LIVEPACK + ${TEXT.TITLE} | %s`} title="" />
      {BootstrappedLayout()}
    </Fragment>
  )
}

export default withRouter(connect(mapStateToProps)(Layout))
